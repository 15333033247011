import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import Home from './pages/home';
import Shop from './pages/shop';
import Contacts from './pages/contacts';

import './assets/css/style.css';

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/shop' component={Shop} />
            <Route exact path='/contacts' component={Contacts} />
          </Switch>
        </Router>
    )
  }
}

export default App;
