import facebook from '../assets/media/facebook.png';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__container'>
        <div className='footer__contacts'>
          <text>Rolfo kasutatud mööbel</text>
          <text>Ringtee 1, Tartu 51013</text>
          <text>+372 53 814 290</text>
        </div>
        <a href='https://www.facebook.com/O%C3%9C-ROLFO-103796521459559' target='_blank'>
          <img src={facebook} className='footer__social' />
        </a>
      </div>
    </div>
  )
}

export default Footer
