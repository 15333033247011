import firebase from "firebase";
const config = {
  apiKey: "AIzaSyBEZbv95k9ijJJycIh007mFAnJ2w_SSefk",
  authDomain: "rolfo-65447.firebaseapp.com",
  projectId: "rolfo-65447",
  storageBucket: "rolfo-65447.appspot.com",
  messagingSenderId: "567714978045",
  appId: "1:567714978045:web:ed965c07da09a695000694"
};
firebase.initializeApp(config);
export const db = firebase.database();
