import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Loader from "react-loader-spinner";
import placeholder from '../assets/media/placeholder.png';
import more from '../assets/media/gallery.png';


import { db } from '../assets/services/firebase';

class Shop extends Component {

  componentDidMount() {
    this.getCategories()
  }

  state = {
    isLoading: true,
    categories: [],
    products: [],
    selectedCategory: 0,
    isOpen: false,
    photoIndex: 0,
    galleryImages: []
  }

  getCategories() {
    db.ref('categories').on('value', snap => {
      let categories = []
      snap.forEach(child => {
        categories.push(child.val())
      })
      this.setState({categories: categories}, function() {
        this.getProducts()
      })
    })
  }

  getProducts() {
    db.ref('products').on('value', snap => {
      let products = []
      snap.forEach(child => {
        products.push({
          image: child.val().imageUrl,
          category: child.val().category,
          heading: child.val().heading,
          description: child.val().description,
          price: child.val().price,
          images: typeof child.val().images == 'undefined' ? [] : child.val().images
        })
      })
      this.setState({products: products, isLoading: false})
    })
  }

  renderCategories() {
    let categories = this.state.categories
    let categoriesList = []
    for(let i=0; i<categories.length; i++) {
      categoriesList.push(
        <div key={i} className={this.state.selectedCategory == i ? 'shopContainer__categories_item_selected' : 'shopContainer__categories_item'} onClick={() => this.setState({selectedCategory: i})}>
          <text style={{textTransform: 'capitalize'}}>{categories[i]}</text>
        </div>
      )
    }
    return categoriesList
  }

  openGallery(pics) {
    let images = []
    db.ref('products').child(pics).child('images').on('value', snap => {
      snap.forEach(child => {
        images.push(child.val())
      })
    })
    this.setState({galleryImages: images}, function() {
      this.setState({isOpen: true})
    })
  }

  renderProducts() {
    let products = this.state.products
    let productsList = []
    for(let i=0; i<products.length; i++) {
      if(products[i].category == this.state.selectedCategory) {
        productsList.push(
          <div key={i} className='shopContainer__productArea_item'>

            {products[i].images.length == 0 ?
              <img key={i} src={placeholder} className='shopContainer__productArea_item_img' />
              :
              products[i].images.length == 1 ?
              Object.keys(products[i].images).slice(0,1).map((value, index) => {
                return (
                  <div>
                    {products[i].images.length < 1 &&
                      <div className='moreImages'>
                        <text>{products[i].images.length}d</text>
                      </div>
                    }
                  <img onClick={(list) => this.setState({deleteImage: products[i].images[value].key})} key={i} src={products[i].images[value].url} className='shopContainer__productArea_item_img' />
                  </div>
                )
              })
              :
              Object.keys(products[i].images).slice(0,1).map((value, index) => {
                return (
                  <div>
                    <img onClick={() => this.openGallery(products[i].images[value].adid)} key={i} src={products[i].images[value].url} className='shopContainer__productArea_item_img' />
                  </div>
                )
              })
            }
            <div className='shopContainer__productArea_item_description'>
              <text class='shopContainer__productArea_item_description_boldText'>{products[i].heading}</text>
              <text class='shopContainer__productArea_item_description_normalText'>{products[i].description}</text>
              <text class='shopContainer__productArea_item_description_priceText'>{products[i].price} €</text>
            </div>
          </div>
        )
      }
    }
    if(productsList.length == 0) {
      return <text>Selles kategoorias tooted hetkel puuduvad või ei ole neid lisatud.</text>
    } else {
      return productsList

    }
  }

  render() {

    const { photoIndex, isOpen, galleryImages } = this.state;

    return (
      <div className="mainContainer">
        <Header />
        <div className='shopContainer'>
          <div className='shopContainer__categories'>
            <div className='shopContainer__categories_header'>
              <text>Tootekategooriad</text>
            </div>
            {this.renderCategories()}
          </div>
          <div className='shopContainer__productArea'>
            {this.state.isLoading ?
              <Loader
                type="Grid"
                color="#2AA100"
                height={50}
                width={100}
                timeout={3000} //3 secs
              />
            :
              this.renderProducts()
            }
          </div>
        </div>
        <Footer/>
        {isOpen && (
          <Lightbox
            mainSrc={galleryImages[photoIndex].url}
            nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
            prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
            onCloseRequest={() => this.setState({ isOpen: false, galleryImages: [] })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + galleryImages.length - 1) % galleryImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % galleryImages.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export default Shop
